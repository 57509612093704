<template>
  <div class="wrap">
    <!-- 顶部黑块 -->
    <div class="wrapTop">
      <!-- 上一页 -->
      <div
        v-if="detail.filetype == 'pdf'"
        @click="pageChange('pre')"
        class="iconfont left icon-anniu_jiantouxiangzuo"
      ></div>
      <!-- 下一页 -->
      <div
        v-if="detail.filetype == 'pdf'"
        @click="pageChange('next')"
        class="iconfont right icon-anniu_jiantouxiangzuo"
      ></div>

      <!-- 视频内容 -->
      <div class="pdf-limit" id="container" v-if="detail.filetype == 'pdf'">
        <pdf
          ref="pdf"
          @num-pages="total = $event"
          @progress="progress"
          @page-loaded="currentPage = $event"
          @loaded="loadPdfHandler"
          @link-clicked="currentPage = $event"
          :page="currentPage"
          class="pdf-box"
          :src="detail.imageFile"
        >
        </pdf>
      </div>
      <div class="videoBox" v-else>
        <iframe
          seamless
          v-if="
            detail.filetype == 'word' ||
            detail.fileType == 'ppt' ||
            detail.fileType == 'excel'
          "
          :src="`https://view.officeapps.live.com/op/view.aspx?src=${detail.imageFile}`"
          width="100%"
          height="100%"
          frameborder="1"
          noResize
        >
        </iframe>
        <iframe
          seamless
          v-else
          :src="detail.imageFile"
          width="100%"
          height="100%"
          frameborder="1"
          noResize
        >
        </iframe>
      </div>
    </div>
    <!-- 选项栏 -->
    <div class="content">
      <div class="tabTwo m-t-24">
        <div
          class="tabTwo-text"
          @click="NavIndex = 1"
          :class="NavIndex == 1 ? 'active' : ''"
        >
          课程答疑
          <div v-if="NavIndex == 1" class="on"></div>
        </div>
        <div
          class="tabTwo-text"
          @click="NavIndex = 2"
          :class="NavIndex == 2 ? 'active' : ''"
        >
          课程评分
          <div v-if="NavIndex == 2" class="on"></div>
        </div>
      </div>
      <!-- 课程答疑 -->
      <answer-view
        :type="1"
        :IsPurchase="IsPurchase"
        :status="5"
        :courseId="courseId"
        :chapterId="id"
        v-if="NavIndex == 1"
      />
      <!-- 课程评分 -->
      <score-evaluation
        v-if="NavIndex == 2"
        :Belonging="2"
        :chapterId="groupId"
        :sectionId="id"
        :type="8"
        :typeId="courseId"
        :typeNmae="detail.title"
        :IsPurchase="IsPurchase"
      />
    </div>
    <el-dialog width="80%" title="" :visible.sync="showLogin">
      <pdf-lock />
    </el-dialog>
  </div>
</template>
<script>
import Bus from "@/api/bus";
import Vue from "vue";
import { Know } from "@/api/know";
let know = new Know();
import pdf from "vue-pdf";
import { getLbClassCourse } from "@/api/mykecheng";
import scoreEvaluation from "@/components/Know/course/scoreEvaluation.vue";
import answerView from "@/components/Know/course/answerView.vue";
import bofangChater from "@/components/Know/bofangChater.vue";
import PdfLock from "../pdfLock.vue";
import { getInfo, getToken } from "@/api/cookies";
export default {
  components: { bofangChater, scoreEvaluation, answerView, pdf, PdfLock },
  data() {
    return {
      userInfo: {},
      // 课程详情信息
      courDetail: {},
      // 课程节详情信息
      detail: {
        courseId: "",
      },
      courseId: "",
      NavIndex: 1,
      IsPurchase: false,
      id: null, //章节ID
      classId: null,
      /* 预览 */
      showLogin: false,
      currentPage: 2, //当前页数
      total: 8, //当前页数
    };
  },
  async created() {
    this.userInfo = getInfo();
    this.id = this.$route.query.id;
    this.classId = this.$route.query.classId ? this.$route.query.classId : null;
    // 录播课程详情信息
    await know.getImageChat(this.id).then((res) => {
      this.detail = res.msg;
      this.detail.filetype = Vue.prototype.getFileType(this.detail.imageFile);
      this.$forceUpdate();
    });
    /*    await getClassCourseById(this.courseId, this.classId ? this.classId : undefined).then((res) => {
      this.IsPurchase = res.data.IsPurchase;
      this.courDetail = res.data.details;
      this.$forceUpdate();
    }); */
    this.getLbClassCourse();
  },
  methods: {
    /* 添加学习记录 */
    async getLbClassCourse() {
      getLbClassCourse(
        4,
        this.userInfo.id,
        this.userInfo.tenantId,
        this.classId ? this.classId : undefined,
        this.id,
        undefined,
        this.detail.courseId
      );
    },
    // 课程概述
    nbvs(val) {
      this.NavIndex = val;
    },
    /* ------------- pdf------------------------------------- */
    // pdf加载时
    loadPdfHandler(e) {
      // 加载的时候先加载第一页
      this.currentPage = 1;
    },
    /* 下一页 */
    pageChange(type) {
      if (type == "pre") {
        if (this.currentPage > 1) {
          this.currentPage--;
        }
      } else {
        if (this.currentPage < this.total) {
          this.currentPage++;
        }
      }
      document.getElementById("container").scrollTop = 0;
      this.$forceUpdate();
    },
    // 加载
    progress(type) {
      this.loadedRatio = type;
      if (type == 1) {
        this.loading.close();
      } else {
        this.loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.1)",
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.wrap {
  width: 100%;
  height: 100%;

  // 顶部黑块
  .wrapTop {
    width: 100%;
    height: 750px;
    background: #222222;
    border-radius: 0px 0px 0px 0px;
    position: relative;
    padding: 32px;
    .iconfont {
      position: absolute;
      font-size: 40px;
      color: rgba(255, 255, 255, 0.5);
      top: calc(50% - 20px);
      &:hover {
        color: rgba(255, 255, 255, 0.7);
      }
    }
    .left {
      left: calc(50% - 610px - 60px);
    }
    .right {
      right: calc(50% - 610px - 60px);
      transform: rotate(180deg);
    }
    /* pdf */
    .pdf-limit {
      width: 1220px;
      height: 686px;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 0px 0px 0px 0px;
      margin: auto;
      scrollbar-width: none;
      overflow-y: scroll;

      .pdf-box {
        width: 100%;
        border-radius: 0px 0px 0px 0px;
        margin: auto;
      }
    }
    .videoBox {
      width: 1220px;
      height: 686px;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 0px 0px 0px 0px;
      overflow-y: scroll;
      scrollbar-width: none;
      margin: auto;
    }
  }

  // 直播课内容
  .warpCon {
    width: 100%;
    // height: 920px;
    height: auto;
    margin-bottom: 30px;
    background: #f5f6fa;
    overflow: hidden;
    // 缩宽
    .warpCons {
      width: 65%;
      height: 100%;
      margin: auto;
      overflow: hidden;
      margin-bottom: 50px;
      .warpConss {
        width: 100%;
        height: auto;
        // 单个内容
        .warpConske {
          width: 100%;
          height: 76px;
          margin-top: 30px;
          background: #ffffff;
          border: 1px solid #ffc3bb;
          border-radius: 2px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          // 左侧标题
          .warpConskeL {
            width: auto;
            height: 19px;
            margin-left: 30px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            img {
              width: 21px;
              height: 19px;
              margin-right: 16px;
              cursor: pointer;
            }
            p {
              width: auto;
              height: auto;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #27323f;
              cursor: pointer;
              span {
                width: auto;
                height: auto;
                margin-left: 20px;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #7b8196;
              }
            }
          }
          // 右侧展开/收起
          .warpConskeR {
            width: auto;
            height: 15px;
            margin-right: 30px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            cursor: pointer;
            img {
              width: 9px;
              height: 7px;
              margin-left: 7px;
            }
            span {
              width: auto;
              height: auto;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #7b8196;
            }
          }
        }
        // 单个内容展开项盒子
        .warpConskexq {
          width: 100%;
          height: auto;
          background: #ffffff;
          padding: 20px;
          .warpConskexqs {
            width: 1175px;
            height: 50px;
            background: #f7f7f7;
            display: flex;
            justify-content: space-between;
            align-items: center;
            // 左侧
            .warpConskexqsL {
              width: auto;
              height: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              div {
                width: 4px;
                height: 50px;
                background: #dddddd;
                margin-right: 27px;
              }
              span {
                width: auto;
                height: auto;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                cursor: pointer;
              }
            }
            // 右侧
            .warpConskexqsR {
              width: 320px;
              height: 32px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              div {
                width: 90px;
                height: 32px;
                margin-right: 10px;
                border-radius: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
    .apple {
      width: 1200px;
      height: 100%;
      margin: 30px auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .appleLeft {
        width: auto;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background: #ffffff;
        .appleLefts {
          width: 100%;
          height: auto;
          /deep/ img {
            width: 100% !important;
          }
        }
      }
    }
  }
  .room {
    width: 320px;
  }
}
.content {
  width: 1220px;
  background: #ffffff;
  margin: 20px auto;
}
//隐藏滚动条
::-webkit-scrollbar {
  display: none !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none;
}
</style>
